import React from 'react';
import { Card, CardBody, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';

import FavIcon from 'mdi-react/HeartOutlineIcon';
import FavIconTrue from 'mdi-react/HeartIcon';
import CheckIcon from 'mdi-react/CheckCircleIcon';
import EditIcon from 'mdi-react/PencilIcon';

import Avatar from 'react-avatar';

// Redux
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';
import validate from '../../Validations/validate';

// Fields
import DefaultInput from '../../../shared/components/form/DefaultInput';

import SharePropsModal from './SharePropsModal';
import ViewPhotosModal from '../../Tables/CommonDataTable/ViewPhotosModal';
import SessionExpireModal from '../../SessionExpire/sessionExpireModal';
import ConfirmActionModal from '../../Tables/CommonDataTable/ConfirmActionModal';
import ContactSellerModal from './ContactSellerModal';
import LoginFormModal from '../../LogIn/components/LoginFormModal';

import { getLoginData } from '../../../redux/actions/loginAction';

import Loader from '../../App/Loader';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import dateFormats from '../../UI/FormatDate/formatDate';

import fetchMethodRequest from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';

import socket from '../../../config/Socket';

//Connected React component will be able to send messages to the store based on actions
function mapDispatchToProps(dispatch) {
    return {
        changeLoginData: loginUser => {
            dispatch(getLoginData(loginUser))
        }
    };
}

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)
class PropertyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            PropertyDetails: {},
            PropertyDetailsArray: [],
            isLoading: false,
            isViewPhotosModalOpen: false,
            tl: {},
            isPhoneVerified: false,
        };
    }

    // This Variable is used for the hiding the elements which is not used rightnow.

    hideElement = false;

    componentDidMount = async () => {
        let tl = {}
        let loc = this.props.location;
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (loc && loc.state && loc.state.tl) {
            tl = loc.state.tl
        } else {
            tl = JSON.parse(localStorage.getItem('langJson'));
        }
        await this.setState({
            tl: tl,
            loginUser: user,
        })
        await this.getPropertyDataBasedOnId();

        if (loc.state && loc.state.fromScreen !== '/matchedPropertiesList') {
            localStorage.setItem('myClientCriteria', JSON.stringify(loc.state.fromCriteria))
            localStorage.setItem('myFilterCriteria', JSON.stringify(loc.state.currentFilterCriteria))
            localStorage.setItem('fromScreen', JSON.stringify(loc.state.fromScreen))
        }
        if (user && user.email && this.props.load) {
            this.props.load({ email: user.email })
        }
    }

    // Redirect Handler when clicked in Back Icon
    redirectToPreviousScreen = async () => {
        await this.setState({
            isNavigateToPreviiusScreen: true,
        })
    }

    // Get Property details based on path params id
    getPropertyDataBasedOnId = async () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'))
        let loc = this.props.location;
        await this.setState({ isLoading: true })
        if (user && user !== 'undefined' && user._id) {
            let favProperties = [];
            if (localStorage.getItem('favProperties') && localStorage.getItem('favProperties') !== 'undefined') {
                favProperties = JSON.parse(localStorage.getItem('favProperties'));
            }
            fetchMethodRequest('GET', `myproperties/${this.props.match.params.id}`)
                .then(async (response) => {
                    if (response && response.details) {
                        let PropertyDetails = response.details;
                        let keys = Object.keys(PropertyDetails);
                        let formData = [];

                        keys.forEach(key => {
                            if (!(key === 'createdBy' || key === '_id' || key === '__v' || key === 'active' || key === 'status' || key === 'photo' || key === 'createdByName' || key === 'deviceName' || key === 'created' || key === 'updated' || key === 'title')) {
                                formData.push(
                                    { label: key, value: PropertyDetails[key] }
                                )
                            }
                        })
                        if (Array.isArray(favProperties) && favProperties.length > 0) {
                            for (var i = 0; i < favProperties.length; i++) {
                                if (favProperties[i] === PropertyDetails._id) {
                                    PropertyDetails['isFavourite'] = true;
                                    break;
                                }
                            }
                        }
                        let phoneNo = (PropertyDetails.phoneNumber && PropertyDetails.phoneNumber !== '') ?
                            PropertyDetails.phoneNumber :
                            PropertyDetails.customerId && PropertyDetails.customerId.phoneNumber ?
                                PropertyDetails.customerId.phoneNumber :
                                ''

                        let phoneStr = phoneNo.toString()
                        let maskedPhoneNo = phoneStr.substr(0, phoneStr.length - 8) + ' * * * * *';
                        await this.setState({
                            PropertyDetails: PropertyDetails,
                            PropertyDetailsArray: formData,
                            isLoading: false,
                            phoneNo: phoneNo,
                            maskedPhoneNo: maskedPhoneNo,
                        })
                    } else {
                        if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                            await this.setState({
                                isOpenSessionExpiredModal: true,
                            })
                        } else {
                            this.setState({ isLoading: false });
                            showToasterMessage(response.errorMessage, 'error');
                        }
                    }
                }).catch((err) => {
                    this.setState({ isLoading: false });
                    return err;
                });
        } else {
            if (loc && loc.state && loc.state.propDetails) {
                let PropertyDetails = loc.state.propDetails;
                let keys = Object.keys(PropertyDetails);
                let formData = [];

                keys.forEach(key => {
                    if (!(key === 'createdBy' || key === '_id' || key === '__v' || key === 'active' || key === 'status' || key === 'photo' || key === 'createdByName' || key === 'deviceName' || key === 'created' || key === 'updated' || key === 'title')) {
                        formData.push(
                            { label: key, value: PropertyDetails[key] }
                        )
                    }
                })
                let phoneNo = PropertyDetails.phoneNumber ?
                    PropertyDetails.phoneNumber :
                    PropertyDetails.customerId && PropertyDetails.customerId.phoneNumber ?
                        PropertyDetails.customerId.phoneNumber :
                        ''

                let phoneStr = phoneNo.toString()
                let maskedPhoneNo = phoneStr.substr(0, phoneStr.length - 8) + ' * * * * *';
                await this.setState({
                    PropertyDetails: PropertyDetails,
                    PropertyDetailsArray: formData,
                    isLoading: false,
                    phoneNo: phoneNo,
                    maskedPhoneNo: maskedPhoneNo,
                })
            }
        }
    }

    // Share Icon onClick handler
    handleSingleShare = async (prop) => {
        let selectedProperties = [];
        selectedProperties.push(prop);

        await this.setState({
            isOpenSharePropsModal: true,
            selectedProperties: selectedProperties,
        })
    }

    // Close share property modal
    closeSharePropsModal = async () => {
        await this.setState({
            isOpenSharePropsModal: false,
        })
    }

    // Handle View Photos Modal
    viewPhotosModal = async (photos) => {
        if (photos && photos === 'close') {
            await this.setState({
                propertyPhotos: null,
                isViewPhotosModalOpen: false
            })
        } else if (photos) {
            await this.setState({
                propertyPhotos: photos,
                isViewPhotosModalOpen: true
            })
        }
    }

    // Handle Property favourite and unfavourtite
    handlePropFav = async (action, prop) => {
        await this.setState({
            isLoading: true,
        })
        if (this.state.loginUser && this.state.loginUser.email) {
            let email = this.state.loginUser.email;
            let apiUrl = 'customers/favAndUnFav';
            let method = 'PUT';
            let type = action;
            let body = {
                properties: [prop._id]
            }
            let url = `${apiUrl}?email=${email}&type=${type}`
            fetchMethodRequest(method, url, body).then(async res => {
                if (res && res.respCode) {
                    showToasterMessage(res.respMessage, 'success');
                    let property = prop;
                    let favProperties = [];
                    let updateFavs = [];
                    if (localStorage.getItem('favProperties') && localStorage.getItem('favProperties') !== 'undefined') {
                        favProperties = JSON.parse(localStorage.getItem('favProperties'));
                    }
                    if (Array.isArray(favProperties)) {
                    } else {
                        favProperties = [];
                    }
                    if (type === 'fav') {
                        property['isFavourite'] = true;
                        favProperties = favProperties.filter(id => {
                            return id !== property._id;
                        });
                        favProperties.push(property._id);
                        updateFavs = [...favProperties];
                    } else {
                        property['isFavourite'] = false;
                        updateFavs = favProperties.filter(id => {
                            return id !== property._id;
                        });
                    }
                    await this.setState({
                        isLoading: false,
                        PropertyDetails: property,
                    })

                    let newFavProps = [...updateFavs];
                    await localStorage.setItem('favProperties', JSON.stringify(newFavProps))
                } else {
                    if (res && res.errorMessage && res.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                            isLoading: false,
                        })
                    } else if (res && res.errorMessage) {
                        showToasterMessage(res.errorMessage, 'error')
                        this.setState({
                            isLoading: false,
                        });
                    } else {
                        showToasterMessage('Network Error', 'error')
                        this.setState({
                            isLoading: false,
                        });
                    }
                }
            }).catch(async err => {
                return err;
            })
        } else {
            this.setState({
                isLoading: false,
                isOpenLoginFormModal: true,
            })
        }
    }

    // Close Login Form Modal
    closeLoginFormModal = async (user) => {
        if (user && user.email) {
            await this.setState({
                loginUser: user,
                isOpenLoginFormModal: false,
                isLoading: false,
            })
            await this.props.changeLoginData(user);
        } else {
            await this.setState({
                isOpenLoginFormModal: false,
                isLoading: false,
            })
        }
    }

    // Close Confirm Action Modal
    closeConfirmActionModal = async () => {
        await this.setState({
            openConfirmActionModal: false,
        })
    }

    // Redirect to login handler
    redirectToLogin = () => {
        this.setState({
            openConfirmActionModal: false,
            isRedirectToLogin: true,
        })
    }

    // Handler to open contact seller modal
    handleContactSeller = async (item) => {
        await this.setState({
            isOpenContactSellerModal: true,
            contactProperty: item,
        })
    }

    // Close Contact Seller Modal
    closeContactSellerModal = async (isVerifySuccess) => {
        let isPhoneVerified = isVerifySuccess;
        await this.setState({
            isOpenContactSellerModal: false,
            isPhoneVerified: isPhoneVerified,
        })
    }

    // On contact seller form submit
    submit = async (values) => {
        let phoneNumber = values.phoneNumber;
        let body = {
            email: values.email,
            phoneNumber: phoneNumber.length === 10 ? '91' + phoneNumber : phoneNumber,
            entityType: 'clients'
        }
        if (values && values.email && phoneNumber) {
            fetchMethodRequest('POST', 'auth/sendOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success');
                    this.props.reset()
                    await this.setState({
                        isSmsSent: true,
                        phoneNumber: phoneNumber,
                        email: values.email,
                        contactName: values.name,
                    })
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                            phoneNumber: phoneNumber,
                            email: values.email,
                            contactName: values.name,
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            phoneNumber: phoneNumber,
                            email: values.email,
                            contactName: values.name,
                        });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            }).catch(err => {
                return err
            })
        }
    }

    // on otp submit
    submitOtp = async (values) => {
        let body = {
            email: this.state.email,
            otp: values.otp,
            entityType: 'clients',
            phoneNumber: this.state.phoneNumber.length === 10 ? '91' + this.state.phoneNumber : this.state.phoneNumber,
        }
        if (this.state.phoneNumber && this.state.email && values.otp) {
            fetchMethodRequest('POST', 'auth/verifyOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    await this.setState({
                        isSmsSent: false,
                        isVerifySuccess: true,
                    })
                    await this.verifySuccess(body);

                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        }
    }

    // Resend otp click handler
    resendOtp = async () => {
        let body = {
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
        }
        await this.submit(body);
    }

    // Otp verification reject handler
    cancelOtpVerification = async () => {
        await this.setState({
            isSmsSent: false,
        })
    }

    // Otp verify success handler
    verifySuccess = async (values) => {
        let PropertyDetails = this.state.PropertyDetails;
        if (PropertyDetails.customerId) {
            socket.emit(
                'agentViewed',
                {
                    contactName: this.state.contactName,
                    contactPhoneNumber: values.phoneNumber,
                    contactEmail: values.email,
                    customerEmail: PropertyDetails.email
                }
            )
        } else {
            socket.emit(
                'agentViewed',
                {
                    contactName: this.state.contactName,
                    contactPhoneNumber: values.phoneNumber,
                    contactEmail: values.email,
                    customerEmail: PropertyDetails.email
                }
            )
        }
        await this.setState({
            showPhoneNumber: true,
        })
        showToasterMessage('Contact shared successfully', 'success')
    }

    resetContactForm = () => {
        this.setState({
            showPhoneNumber: false,
            isSmsSent: false,
            isVerifySuccess: false,
        })
    }

    editPhoneNumber = async () => {
        await this.setState({
            isSmsSent: false,
        })
        if (this.props.load) {
            let body = {
                name: this.state.contactName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
            }
            await this.props.load(body);
        }
    }

    render() {
        const { PropertyDetails, tl, phoneNo, maskedPhoneNo, showPhoneNumber, isPhoneVerified, isSmsSent, isVerifySuccess } = this.state;
        const { handleSubmit } = this.props;
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                <Card>
                    <CardBody className='px-3'>
                        <div className='pd_headerContainer row'>
                            <div className='pd_head_titleContainer col-sm-8 px-0'>
                                <div className='pd_title'>
                                    <h3>
                                        {PropertyDetails.title ? PropertyDetails.title.split('in')[0] : ''}
                                        {/* {PropertyDetails && PropertyDetails.isFavourite ?
                                            <FavIconTrue
                                                className='pd_favIcon'
                                                onClick={() => this.handlePropFav('unfav', PropertyDetails)}
                                            />
                                            :
                                            <FavIcon
                                                className='pd_favIcon'
                                                onClick={() => this.handlePropFav('fav', PropertyDetails)}
                                            />
                                        } */}
                                    </h3>
                                </div>
                                <div className='pd_address text-capitalize'>
                                    {PropertyDetails.landmark ? PropertyDetails.landmark + ', ' : ''}
                                    {PropertyDetails.locality ? PropertyDetails.locality + ', ' : ''}
                                    {PropertyDetails.city ? PropertyDetails.city : ''}
                                </div>
                            </div>
                            <div className='pd_head_priceContainer col-sm-4 px-0'>
                                <div class="row">
                                    <div class="col-6 col-lg-12 pd_head_price">
                                        <h3 class="textCapitalize lightText">
                                            {PropertyDetails.priceInIndianFormat}
                                        </h3>
                                    </div>
                                    <div class="col-6 col-lg-12 pd_head_area d-flex justify-content-end align-items-center" style={{ color: 'black'}}>
                                        <span className="d-block d-sm-none me-1">Area:</span>
                                        {PropertyDetails.builtUpArea ? (parseInt(PropertyDetails.builtUpArea)).toLocaleString('en-IN') + ' ' + PropertyDetails.areaUnits
                                            : PropertyDetails.plotArea ? (parseInt(PropertyDetails.plotArea)).toLocaleString('en-IN') + ' ' + PropertyDetails.areaUnits : null}
                                    </div>
                                </div>
                                {this.hideElement && <Button
                                    className="mb-0 mt-2 mx-0"
                                    color="primary"
                                    type='button'
                                    onClick={() => this.handleContactSeller(PropertyDetails)}
                                >
                                    {tl['contactSeller'] ? tl['contactSeller'] : 'Contact Seller'}
                                </Button>}
                            </div>
                        </div>
                        <div className='pd_gallery_container px-0 mt-0'>

                            {PropertyDetails.browsePhotos && PropertyDetails.browsePhotos.length > 0 ? (
                                PropertyDetails.browsePhotos.length === 1 ? (
                                    <img
                                        className='pd_singleImage'
                                        src={`${config.imgUrl}myproperties/${typeof PropertyDetails.browsePhotos[0] === 'object' ? PropertyDetails.browsePhotos[0].name : PropertyDetails.browsePhotos[0]}`}
                                        alt="propPhoto.jpg"
                                        onClick={() => this.viewPhotosModal(PropertyDetails.browsePhotos)}
                                    />
                                ) : (
                                    <div className='pd_galleryGrid'>
                                        <div className='pd_gridImage1'>
                                            {PropertyDetails.browsePhotos[0] && (
                                                <img
                                                    className='pd_gridImageChild'
                                                    src={`${config.imgUrl}myproperties/${typeof PropertyDetails.browsePhotos[0] === 'object' ? PropertyDetails.browsePhotos[0].name : PropertyDetails.browsePhotos[0]}`}
                                                    alt="propPhoto.jpg"
                                                    onClick={() => this.viewPhotosModal(PropertyDetails.browsePhotos)}
                                                />
                                            )}
                                        </div>
                                        <div className='pd_gridChild'>
                                            {PropertyDetails.browsePhotos[1] && (
                                                <img
                                                    className='pd_gridImageChild'
                                                    src={`${config.imgUrl}myproperties/${typeof PropertyDetails.browsePhotos[1] === 'object' ? PropertyDetails.browsePhotos[1].name : PropertyDetails.browsePhotos[1]}`}
                                                    alt="propPhoto.jpg"
                                                    onClick={() => this.viewPhotosModal(PropertyDetails.browsePhotos)}
                                                />
                                            )}
                                        </div>
                                        <div className='pd_gridChild'>
                                            {PropertyDetails.browsePhotos[2] && (
                                                <img
                                                    className='pd_gridImageChild'
                                                    src={`${config.imgUrl}myproperties/${typeof PropertyDetails.browsePhotos[2] === 'object' ? PropertyDetails.browsePhotos[2].name : PropertyDetails.browsePhotos[2]}`}
                                                    alt="propPhoto.jpg"
                                                    onClick={() => this.viewPhotosModal(PropertyDetails.browsePhotos)}
                                                />
                                            )}
                                            {PropertyDetails.browsePhotos.length > 3 && (
                                                <div
                                                    className="pd_imageCount"
                                                    onClick={() => this.viewPhotosModal(PropertyDetails.browsePhotos)}
                                                >
                                                    <div className='pd_imageCountText'>
                                                        {'+ ' + (PropertyDetails.browsePhotos.length - 3) + ' '}
                                                        {tl['more'] ? tl['more'] : 'More'}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            ) : (
                                <img
                                    className='pd_noImage'
                                    src={configImages.noImage}
                                    alt="no photos"
                                />
                            )}

                        </div>
                        <div className='pd_baseInfo_container row'>
                            <div className='col-12 col-sm-6 col-md-3  pd_baseInfoItem'>
                                <h4>
                                    {PropertyDetails.propertyType}
                                </h4>
                                <div className='textCapitalize lightText'>
                                    {tl['propertyType'] ? tl['propertyType'] : 'Property Type'}
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-3  pd_baseInfoItem'>
                                <h4>
                                    {PropertyDetails.furnishingType}
                                </h4>
                                <div className='textCapitalize lightText'>
                                    {tl['furnishingType'] ? tl['furnishingType'] : 'Furnishing Type'}
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-3  pd_baseInfoItem'>
                                <h4>
                                    {PropertyDetails.facing}
                                </h4>
                                <div className='textCapitalize lightText'>
                                    {tl['facing'] ? tl['facing'] : 'Facing'}
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-md-3  pd_baseInfoItem'>
                                <h4>
                                    {PropertyDetails.availability}
                                </h4>
                                <div className='textCapitalize lightText'>
                                    {tl['availability'] ? tl['availability'] : 'Availability'}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                    <div className='pd_data_container'>
                        <div className='pd_data_content row'>
                            <div className="col-md-8 mt-4">
                                <div className='pd_nav_content_overview'>
                                    <h3>
                                        {tl['overview'] ? tl['overview'] : 'Overview'}
                                    </h3>
                                    {/* Property Overview Details */}
                                    <div className="row mx-0">
                                        <div className='col-6 pd_overview_data'>
                                            <div className='textCapitalize lightText'>
                                                {tl['propertyFor'] ? tl['propertyFor'] : 'Property For'}
                                            </div>
                                            {PropertyDetails.propertyFor}
                                        </div>

                                        {PropertyDetails.propertyFor === 'Sale' ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['price'] ? tl['price'] : 'Price'}
                                                </div>
                                                {PropertyDetails.priceInIndianFormat}
                                            </div> : null
                                        }
                                        {PropertyDetails.propertyFor === 'Rent' ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['rent'] ? tl['rent'] : 'Rent'}
                                                </div>
                                                {PropertyDetails.priceInIndianFormat}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.propertyFor === 'Rent' ?
                                            PropertyDetails.deposite ?
                                                <div className='col-6 pd_overview_data'>
                                                    <div className='textCapitalize lightText'>
                                                        {tl['deposit'] ? tl['deposit'] : 'Deposit'}
                                                    </div>
                                                    {PropertyDetails.depositeInIndianFormat}
                                                </div>
                                                : null
                                            : null
                                        }
                                        {PropertyDetails.propertyFor === 'Rent' ?
                                            PropertyDetails.monthlyMaintenance ?
                                                <div className='col-6 pd_overview_data'>
                                                    <div className='textCapitalize lightText'>
                                                        {tl['maintenance'] ? tl['maintenance'] : 'Maintenance'}
                                                    </div>
                                                    {PropertyDetails.monthlyMaintenanceInIndianFormat}
                                                </div>
                                                : null
                                            : null
                                        }
                                        {/* {PropertyDetails.propertyType === 'Commercial' ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['plotArea'] ? tl['plotArea'] : 'Plot Area'}
                                                </div>
                                                {PropertyDetails.plotArea ?
                                                    (parseInt(PropertyDetails.plotArea)).toLocaleString('en-IN') + ' ' + PropertyDetails.areaUnits
                                                    : null
                                                }
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.propertyType !== 'Commercial' && PropertyDetails.builtUpArea ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['builtUpArea'] ? tl['builtUpArea'] : 'Built-Up Area'}
                                                </div>
                                                {(parseInt(PropertyDetails.builtUpArea)).toLocaleString('en-IN') + ' ' + PropertyDetails.areaUnits}
                                            </div>
                                            : null
                                        } */}
                                        {PropertyDetails.propertyType !== 'Commercial' ?
                                            PropertyDetails.carpetArea ?
                                                <div className='col-6 pd_overview_data'>
                                                    <div className='textCapitalize lightText'>
                                                        {tl['carpetArea'] ? tl['carpetArea'] : 'Carpet Area'}
                                                    </div>
                                                    {PropertyDetails.carpetArea ?
                                                        (parseInt(PropertyDetails.carpetArea)).toLocaleString('en-IN') + ' ' + PropertyDetails.areaUnits
                                                        : null
                                                    }
                                                </div>
                                                : null
                                            : null
                                        }

                                        {PropertyDetails.bedRooms ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['bedRooms'] ? tl['bedRooms'] : 'Bedrooms'}
                                                </div>
                                                {PropertyDetails.bedRooms}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.bathRooms ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['bathRooms'] ? tl['bathRooms'] : 'Bathrooms'}
                                                </div>
                                                {PropertyDetails.bathRooms}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.numberOfFloors ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['noOfFloors'] ? tl['noOfFloors'] : 'Number Of Floors'}
                                                </div>
                                                {PropertyDetails.numberOfFloors}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.floorNumber ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['floorNumber'] ? tl['floorNumber'] : 'Floor Number'}
                                                </div>
                                                {PropertyDetails.floorNumber}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.city ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['city'] ? tl['city'] : 'City'}
                                                </div>
                                                {PropertyDetails.city}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.locality ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['locality'] ? tl['locality'] : 'Locality'}
                                                </div>
                                                {PropertyDetails.locality}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.landmark ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['landMark'] ? tl['landMark'] : 'Land Mark'}
                                                </div>
                                                {PropertyDetails.landmark}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.amenties ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['amenities'] ? tl['amenities'] : 'Amenities'}
                                                </div>
                                                {Array.isArray(PropertyDetails.amenties) && PropertyDetails.amenties.length > 0 ? (
                                                    PropertyDetails.amenties.map((options, index) => (
                                                        options.trim() !== "" && (
                                                            <li key={index}>
                                                                {options}
                                                            </li>
                                                        )
                                                    ))
                                                ) : null}

                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.description ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['description'] ? tl['description'] : 'Description'}
                                                </div>
                                                {PropertyDetails.description}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.onClickCount ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['viewCount'] ? tl['viewCount'] : 'View Count'}
                                                </div>
                                                {PropertyDetails.onClickCount}
                                            </div>
                                            : null
                                        }
                                        {PropertyDetails.created ?
                                            <div className='col-6 pd_overview_data'>
                                                <div className='textCapitalize lightText'>
                                                    {tl['added'] ? tl['added'] : 'Added'}
                                                </div>
                                                {dateFormats.isHowLong(PropertyDetails.created)}
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {this.hideElement && <div className="col-md-5 mt-4">
                                <div className='pd_nav_content_contact'>
                                    <div className='pd_contact_childOne'>
                                        {tl['contactSeller'] ? tl['contactSeller'] : 'Contact Seller'}
                                    </div>
                                    <div className='pd_contact_childTwo'>
                                        <Avatar
                                            name={PropertyDetails.displayName ? PropertyDetails.displayName : PropertyDetails.customerId && PropertyDetails.customerId.displayName ? PropertyDetails.customerId.displayName : ''}
                                            round={true}
                                            size={40}
                                            maxInitials={2}
                                            color={config.themeColor}
                                            fgColor={config.themeWhite}
                                            textSizeRatio={2.8}
                                            className='mr-2'
                                        />
                                        <div className='text-left'>
                                            <p className='textCapitalize'>
                                                {
                                                    PropertyDetails.displayName ? PropertyDetails.displayName : PropertyDetails.customerId && PropertyDetails.customerId.displayName ? PropertyDetails.customerId.displayName : ''
                                                }
                                            </p>
                                            <p className='mt-0'>
                                                {(showPhoneNumber || isPhoneVerified) ?
                                                    phoneNo
                                                    : maskedPhoneNo
                                                }
                                            </p>
                                            {isVerifySuccess ?
                                                <p className='mt-0 useVerifiedNumberText'>
                                                    {tl['pleaseUseRegisteredNumber'] ? tl['pleaseUseRegisteredNumber'] : 'Please use your registered number'}{' - '}
                                                    {this.state.phoneNumber ? this.state.phoneNumber : ''}{' '}
                                                    {tl['to'] ? tl['to'] : 'to'}{' '}
                                                    {tl['contactTheSeller'] ? tl['contactTheSeller'] : 'contact the seller'}
                                                </p>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <div className=' pd_contactForm'>
                                        {isSmsSent ?
                                            <form
                                                className='justify-content-center'
                                                onSubmit={handleSubmit(this.submitOtp)}>
                                                <div className="form">
                                                    <h5 className="mb-3">
                                                        <span>{tl['verifyPhoneText'] ? tl['verifyPhoneText'] : 'Please enter the verification code sent to'}{' '}</span>
                                                        <span>{this.state.phoneNumber}</span>
                                                        <EditIcon
                                                            className='contactSellerPhoneEdit'
                                                            onClick={() => this.editPhoneNumber()}
                                                        />
                                                    </h5>
                                                    <div className="form__form-group">
                                                        <div className="form__form-group-field">
                                                            <Field
                                                                name='otp'
                                                                component={DefaultInput}
                                                                placeholder={tl['enterOtp'] ? tl['enterOtp'] : 'Please enter 6 digit OTP'}
                                                                validate={required}
                                                                maxLength={6}
                                                            />
                                                        </div>
                                                        <div className="form__form-group-field redendOtpText">
                                                            <span
                                                                onClick={this.resendOtp}
                                                            >
                                                                {tl['resendOtp'] ? tl['resendOtp'] : 'Resend Otp'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 px-0 text-center mt-2">
                                                    <Button
                                                        color="primary"
                                                        type="button"
                                                        className='mb-0 mt-2 mx-1'
                                                        onClick={this.cancelOtpVerification}
                                                    >
                                                        {tl['cancel'] ? tl['cancel'] : 'Cancel'}
                                                    </Button>
                                                    <Button
                                                        color='primary'
                                                        type="submit"
                                                        className='mb-0 mt-2 mx-1'
                                                    >
                                                        {tl['verify'] ? tl['verify'] : 'Verify'}
                                                    </Button>
                                                </div>
                                            </form>
                                            : isVerifySuccess ?
                                                <div className="mb-2">
                                                    <h5 className='text-left otpVerifySuccessText'>
                                                        <CheckIcon
                                                            color={config.themeGreen}
                                                            className='mr-2'
                                                        />
                                                        {tl['yourDetailsHaveBeenSent'] ? tl['yourDetailsHaveBeenSent'] : 'Your details have been sent'}!
                                                    </h5>
                                                    <div className="col-12 px-0 text-center">
                                                        <Button
                                                            className="mb-0 mt-2 mx-1"
                                                            color="primary"
                                                            type='button'
                                                            onClick={this.resetContactForm}
                                                        >
                                                            {tl['reset'] ? tl['reset'] : 'Reset'}
                                                        </Button>
                                                    </div>
                                                </div>
                                                :
                                                <form onSubmit={handleSubmit(this.submit)}>
                                                    <div className="form">
                                                        <div className="mb-1">
                                                            <h5>
                                                                {tl['getContactDetails'] ? tl['getContactDetails'] : 'Get Contact Details'}
                                                            </h5>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name='name'
                                                                    component={DefaultInput}
                                                                    type="name"
                                                                    placeholder={tl['name'] ? tl['name'] : 'Name'}
                                                                    validate={required}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name='phoneNumber'
                                                                    component={DefaultInput}
                                                                    type="phone"
                                                                    placeholder={tl['phone'] ? tl['phone'] : 'phone'}
                                                                    validate={required}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form__form-group">
                                                            <div className="form__form-group-field">
                                                                <Field
                                                                    name='email'
                                                                    component={DefaultInput}
                                                                    placeholder={tl['email'] ? tl['email'] : 'Email'}
                                                                    validate={required}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-0 text-center mt-2">
                                                        <Button
                                                            className="mb-0 mt-2 mx-1"
                                                            color="primary"
                                                            type='submit'
                                                        >
                                                            {tl['getContactDetails'] ? tl['getContactDetails'] : 'Get contact details'}
                                                        </Button>
                                                    </div>
                                                </form>
                                        }
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Card >

                {/* Navigate to previous screen */}
                {
                    this.state.isNavigateToPreviiusScreen ?
                        <Redirect
                            to={{
                                pathname: this.props.location.state.fromScreen,
                                state: {
                                    prevCriteria: this.props.location.state.fromCriteria,
                                    prevData: this.props.location.state.fromData,
                                    prevScreen: this.props.location.state.fromScreen,
                                    ClientIdForMatcheProps: this.props.location.state.ClientIdForMatcheProps,
                                    prevFilterCriteria: this.props.location.state.currentFilterCriteria,
                                    criteriaForMatchedProps: this.props.location.state.criteriaForMatchedProps,
                                    tl: tl,
                                    state: this.props.location.state.state,
                                    matchedFromScreen: this.props.location.state.matchedFromScreen,
                                }
                            }} />
                        : null
                }

                {/* Share Property Modal */}
                {
                    this.state.isOpenSharePropsModal ?
                        <SharePropsModal
                            isOpenSharePropsModal={this.state.isOpenSharePropsModal}
                            selectedProperties={this.state.selectedProperties}
                            closeSharePropsModal={this.closeSharePropsModal}
                            tl={tl}
                        />
                        : null
                }

                {/* #Session Expire Modal */}
                {
                    this.state.isOpenSessionExpiredModal ?
                        <SessionExpireModal
                            isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                            tl={tl}
                        />
                        : null
                }

                {/* Photos Viewer */}
                {
                    this.state.isViewPhotosModalOpen ?
                        <ViewPhotosModal
                            isViewPhotosModalOpen={this.state.isViewPhotosModalOpen}
                            propertyPhotos={this.state.propertyPhotos}
                            closeViewPhotosModal={this.viewPhotosModal}
                            tl={tl}
                        />
                        : null
                }

                {this.state.openConfirmActionModal ?
                    <ConfirmActionModal
                        openConfirmActionModal={this.state.openConfirmActionModal}
                        closeConfirmActionModal={this.closeConfirmActionModal}
                        deleteSelectedRow={this.deleteSelectedRow}
                        favSelectedClients={this.favSelectedClients}
                        actionType={this.state.actionType}
                        actionTitle={this.state.actionTitle}
                        redirectToLogin={this.redirectToLogin}
                        tl={tl}
                    />
                    : null
                }

                {this.state.isRedirectToLogin ?
                    <Redirect
                        to={{
                            pathname: '/log_in',
                            state: { tl: tl }
                        }}
                    />
                    : null
                }

                {/* Contact seller Modal */}
                {this.state.isOpenContactSellerModal ?
                    <ContactSellerModal
                        isOpenContactSellerModal={this.state.isOpenContactSellerModal}
                        PropertyDetails={this.state.contactProperty}
                        tl={tl}
                        closeContactSellerModal={this.closeContactSellerModal}
                    />
                    : null
                }

                {/* Login Form Modal */}
                {this.state.isOpenLoginFormModal ?
                    <LoginFormModal
                        isOpenLoginFormModal={this.state.isOpenLoginFormModal}
                        closeLoginFormModal={this.closeLoginFormModal}
                        tl={tl}
                    />
                    : null
                }
            </div >
        );
    }
}

PropertyDetails = reduxForm({
    form: 'pd_contact_form', // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(PropertyDetails);

// You have to connect() to any reducers that you wish to connect to yourself
PropertyDetails = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(PropertyDetails);

const PropDetailsConnect = connect(mapDispatchToProps)(PropertyDetails);

export default PropDetailsConnect